import React from "react";
import {AiFillBook} from "react-icons/ai";
import { useAllContext } from "../context/context";
import LoadingSpinner from "../loading-spinner";
import {FaFacebookF, FaInstagram, FaLinkedinIn, FaTiktok, FaWhatsapp, FaYoutube} from "react-icons/fa";
import {FaX} from "react-icons/fa6";
import {Link} from "react-router-dom";

const Footer = () => {
  const getYear = () => {
    return new Date().getFullYear();
  };
  const {
    allPages,
    allFooters,
  } = useAllContext();

  return (
    <div className="footer section-padding-t">
      <div className="container">
        {allFooters.length === 0 ? (
            <LoadingSpinner className="mb-4 col-md-6 col-lg-3"/>
        ) : (
            <div className="row">
              {
                allFooters
                    .filter((footer) => footer.title.toLowerCase())
                    .map((footer) => (

                        <div className="col-md-4" key={footer.id}>
                          <div className="footer__top">
                            <div className="footer__top--icon" dangerouslySetInnerHTML={{__html: footer.icon}}/>
                            <div className="footer__top--info">
                              <h3>{footer.title}</h3>
                              <p dangerouslySetInnerHTML={{__html: footer.desc}}/>
                            </div>
                          </div>
                        </div>

                    ))
              }
            </div>
        )}

        {  allPages
            .filter((pages) => pages.title.toLowerCase())
            .map((pages) => (
                <div key={pages.id}>
                  <hr/>
                  <div className="footer__bottom">
                    <div>
                      <h3>
                        <Link to="/">
                          {pages.title}
                        </Link>
                      </h3>
                      <span>{pages.subtitle}</span>
                    </div>
                    <div className="m-3">
                      <ul className="subscribe__content--social">
                        {pages.facebook ? (
                            <li>
                              <a href={pages.facebook} target="_blank" rel="noreferrer">
                                <FaFacebookF/>
                              </a>
                            </li>
                        ) : ("")}
                        {pages.x ? (
                            <li>
                              <a href={pages.x} target="_blank" rel="noreferrer">
                                <FaX/>
                              </a>
                            </li>
                        ) : ("")}
                        {pages.instagram ? (
                            <li>
                              <a href={allPages[0].instagram} target="_blank" rel="noreferrer">
                                <FaInstagram/>
                              </a>
                            </li>
                        ) : ("")}
                        {pages.linkedin ? (
                            <li>
                              <a href={allPages[0].linkedin} target="_blank" rel="noreferrer">
                                <FaLinkedinIn/>
                              </a>
                            </li>
                        ) : ("")}
                        {pages.youtube ? (
                            <li>
                              <a href={pages.youtube} target="_blank" rel="noreferrer">
                                <FaYoutube/>
                              </a>
                            </li>
                        ) : ("")}
                        {pages.tiktok ? (
                            <li>
                              <a href={pages.tiktok} target="_blank" rel="noreferrer">
                                <FaTiktok/>
                              </a>
                            </li>
                        ) : ("")}
                        {pages.whatsapp ? (
                            <li>
                              <a href={allPages[0].whatsapp} target="_blank" rel="noreferrer">
                                <FaWhatsapp/>
                              </a>
                            </li>
                        ) : ("")}
                      </ul>
                    </div>
                    <div>
                      <img src={pages.img} alt={pages.title}/>
                      <p>
                        © {getYear()} Todos os direitos reservados <AiFillBook/>.
                      </p>
                    </div>
                  </div>
                </div>
            ))}
      </div>
    </div>
  );
};

export default Footer;
