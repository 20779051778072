import { signOut } from "firebase/auth";
import { useState } from "react";
import { Navbar } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { CgShoppingBag } from "react-icons/cg";
import { FiPhoneCall } from "react-icons/fi";
import { Link } from "react-router-dom";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import Drawer from "react-modern-drawer";
import { auth } from "../../firebase";
import Cart from "../cart";
import Confirm from "../confirm";
import { useAllContext } from "../context/context";
import {useFilterContext} from "../context/filter_context";

const Header = ({ headers }) => {
  const { executeScroll, cart, setCart, price, setQuery, admin, allPages, allBooks} = useAllContext();
  const [fix, setFix] = useState(false);
  const [search, setSearch] = useState(false);
  const [signOutMessage, setSignOutMessage] = useState("");
  let pageId = useState(null);
  let pageTitle = useState(null);
  let pageCellPhone = useState(null);
  let pageImg = useState(null);

  // TO GET THE UNIQUE DATA OF EACH FIELDS
  const getUniqueData = (data, property) => {
    let newVal = data.map((curElem) => {
      return curElem[property];
    });
    return (newVal = ["todos", ...new Set(newVal.sort())]);
  };

  const categoryData = getUniqueData(allBooks, "category");

  function setFixed() {
    if (window.scrollY >= 100) {
      setFix(true);
    } else {
      setFix(false);
    }
  }
  window.addEventListener("scroll", setFixed);
  function setSearchField() {
    if (window.scrollY >= 470) {
      setSearch(true);
    } else {
      setSearch(false);
    }
  }
  window.addEventListener("scroll", setSearchField);

  // Drawer
  const [headerOpen, setHeaderOpen] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  const [confirm, setConfirm] = useState(false);

  const headerDrawer = () => {
    setHeaderOpen((prevState) => !prevState);
  };
  const cartDrawer = () => {
    setCartOpen((prevState) => !prevState);
  };

  const confirmBook = () => {
    setConfirm((prevState) => !prevState);
    setCartOpen(false);
  };
  const backToCart = () => {
    setConfirm((prevState) => !prevState);
    setCartOpen(true);
  };

  let cartItem = cart === null ? 0 : cart.length;

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        setSignOutMessage("Logout com sucesso");
      })
      .catch((error) => {
        console.log(error.message);
      });
  };

  const baseClass = `${fix ? "header navbar_fixed" : "header"} ${
    headers === "all-book" ? "all-book" : ""
  } ${headers === "minimal" ? "minimal" : ""} ${
    headers === "modern" ? "modern" : ""
  } ${headers === "classic" ? "classic" : ""} ${
    headers === "manage-book" ? "manage-book" : ""
  } ${search ? "search" : ""} ${
    headers === "add-book" ? "add-book" : ""
  }`.trim();

  if (Object.keys(allPages).length > 0){
     pageId= allPages[0].id;
     pageTitle = allPages[0].title;
     pageCellPhone = allPages[0].cellphone;
     pageImg = allPages[0].img;
  }

  const {
    filters: {category},
    updateFilterValue,
  } = useFilterContext();

  return (
    <header className={baseClass}>
      <div className="container">
        <div className="row">
          <Navbar className="p-0" key={pageId}>
            <button className="header__menu-btn" onClick={headerDrawer}>
              <span className="header__menu-btn__icon">
                <span className="bar"></span>
                <span className="bar"></span>
                <span className="bar"></span>
              </span>
            </button>
            <Link to="/" className="header__logo">
              <h1 className="m-0 text-nowrap">{pageTitle}</h1>
            </Link>
            <div className="header__search">
              <form>
                <span className="header__search--icon">
                  <BsSearch />
                </span>
                <input
                    type="text"
                    placeholder="Pesquise seu livro aqui"
                    onClick={(e) => executeScroll()}
                    onChange={(e) => setQuery((e.target.value).toLowerCase())}
                />
              </form>
            </div>
            <div className="header__call">
              <FiPhoneCall /> <a href={"tel:" + pageCellPhone}>{pageCellPhone}</a>
            </div>
            <button className="header__cart-btn" onClick={cartDrawer}>
              <CgShoppingBag />
              <span>{cart === null ? 0 : cart.length}</span>
            </button>
            <Drawer
                open={headerOpen}
                onClose={headerDrawer}
                direction="left"
                className=" drawer"
                size={400}
            >
              <div className="header-menu">
                <div className="header-menu__top">
                  <img src={pageImg} alt={pageTitle}/>
                  <h1>{pageTitle}</h1>
                  <span onClick={headerDrawer}>
                    <MdOutlineClose/>
                  </span>
                </div>
                <ul className="bs-scroll">
                  <Link to="/">
                    <li>Página ínicial</li>
                  </Link>
                  <Link to="/sobre">
                    <li>Sobre</li>
                  </Link>
                  {/*<Link to="/salve-se-classico"> <li>Salve-se Clássico</li></Link> */}
                  <hr/>
                  <div className="filter__item">
                    <div className="filter__item--category">
                      <li style={{margin: 0, color: "#5A2B3B"}}>Categoria:
                        <ol style={{padding: 0}}>
                          {categoryData.map((curElem, i) => {
                            return (
                                <Link key={i} to="/todos-livros">
                                  <li onClick={updateFilterValue} style={{padding: 10, margin: 0}}>
                                    <button
                                        key={i}
                                        type="button"
                                        name="category"
                                        value={curElem}
                                        className={curElem === category ? "active" : ""}
                                        onClick={updateFilterValue}
                                    >
                                      {curElem}
                                    </button>
                                  </li>
                                </Link>
                            );
                          })}
                        </ol>
                      </li>
                    </div>
                  </div>
                  <hr/>
                  {admin ? (
                      <>
                        <Link to="/gerenciar-banner">
                          <li>Gerir banners</li>
                        </Link>
                        <Link to="/gerenciar-oferta">
                          <li>Gerir ofertas</li>
                        </Link>
                        <Link to="/gerenciar-livro">
                          <li>Gerir livros</li>
                        </Link>
                        <Link to="/gerenciar-palavra">
                          <li>Gerir palavras</li>
                        </Link>
                        <Link to="/gerenciar-imagem">
                          <li>Gerir Imagens</li>
                        </Link>
                        <Link to="/gerenciar-video">
                          <li>Gerir Videos</li>
                        </Link>
                        <hr/>
                        <Link to="/gerenciar-pagina">
                          <li>Configurar Página</li>
                        </Link>
                        <Link to="/gerenciar-rodape">
                          <li>Configurar Rodapé</li>
                        </Link>
                        <li>
                          <button
                              className="button button__primary"
                              onClick={handleSignOut}
                          >
                            <span>Sair</span>
                          </button>
                        </li>
                      </>
                  ) : (
                      <Link to="/login">
                        <li>Login</li>
                      </Link>
                  )}
                  <li>{signOutMessage}</li>
                </ul>
              </div>
            </Drawer>
            <Drawer
                open={cartOpen}
                onClose={cartDrawer}
                direction="right"
                className=" drawer"
                size={450}
            >
              {cartItem === 0 ? (
                  <div className="cart">
                    <div className="row">
                      <div className="section-title-center text-center">
                        <div className="cart__close" onClick={cartDrawer}>
                          <MdOutlineClose/>
                        </div>
                        <h2 className="fs-5">Não há itens no seu carrinho</h2>
                        <div className="section-divider divider-triangle"></div>
                      </div>
                    </div>
                    <div className="cart__empty">
                      <p>
                        Você ainda não adicionou nada ao seu carrinho. Comece a adicionar
                        os livros que você gosta.
                      </p>
                      <AiOutlineShoppingCart />
                    </div>
                    <div className="cart__confirm">
                      <button className="cart__btn-offline" disabled>
                        Confirmar
                      </button>
                    </div>
                  </div>
              ) : (
                  <Cart cartDrawer={cartDrawer} confirmBook={confirmBook} />
              )}
            </Drawer>
            <Drawer
                open={confirm}
                onClose={confirmBook}
                direction="right"
                className=" drawer"
                size={450}
            >
              <Confirm
                  price={price}
                  backToCart={backToCart}
                  setConfirm={setConfirm}
                  cart={cart}
                  setCart={setCart}
              />
            </Drawer>
          </Navbar>
        </div>
      </div>
    </header>
  );
};

export default Header;
