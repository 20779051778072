import React from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  MdOutlineKeyboardArrowLeft,
  MdOutlineNavigateNext,
} from "react-icons/md";
import { Link } from "react-router-dom";
import { useAllContext } from "./context/context";


const Hero = () => {
  const {
    allBanners,
  } = useAllContext();
  return (
    <div className="hero section-padding-t section-bg">
      <div className="container">
        <div className="row testi-row">
          <div className="col-12">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              loop={true}
              autoplay
              pagination={{ clickable: true }}
              navigation={{
                prevEl: ".prev",
                nextEl: ".next",
              }}
              className="hero__swiper"
            >
              {allBanners.length === 0 ? (
                  <SwiperSlide>
                    <div className="row align-items-center">
                      <div className="col-md-7 col-lg-6 mb-4 mb-lg-0">
                        <div className="hero__content position-relative">
                          <div className="badge-text mb-2 text-uppercase">
                            REFLEXÕES & CONVICÇÕES DO MEMORANDO
                          </div>
                          <h1 className="display-4 mb-4 text-capitalize">
                            Crie a sua máxima: E dê o seu máximo por ela
                          </h1>
                          <p className="text-muted mb-5 fs-5">
                            Sir. Carlos Guida.
                          </p>
                          <Link to="/todos-livros" className="button button__primary">
                            <span>Compre agora</span>
                          </Link>
                        </div>
                      </div>
                      <div className="col-md-5 offset-lg-1 text-center">
                        <div className="hero__image2 text-center">
                          <img
                              className="img-fluid"
                              src="assets/images/hero5.jpg"
                              alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </SwiperSlide>
              ) : (
                  allBanners
                      .filter((banner) => banner.title.toLowerCase())
                      .filter((banner) => banner.title.toUpperCase())
                      .map((banner) => (
                          <SwiperSlide key={banner.id}>
                            <div className="row align-items-center" >
                              <div className="col-md-7 col-lg-6 mb-4 mb-lg-0">
                                <div className="hero__content position-relative">
                                  <h1 className="display-4 mb-4 text-capitalize">
                                    {banner.title}
                                  </h1>
                                  <div className="badge-text mb-2 text-uppercase">
                                    {banner.subtitle}
                                  </div>
                                  <p className="text-muted mb-5 fs-5">
                                    {banner.desc}<br/>
                                    <span style={{fontSize: "80%"}} className="text-muted">{banner.author}</span>
                                  </p>
                                  <Link to="/todos-livros" className="button button__primary">
                                    <span>Compre agora</span>
                                  </Link>
                                </div>
                              </div>
                              <div className="col-md-5 offset-lg-1 text-center">
                              <div className="hero__image2 text-center">
                                  <img
                                      className="img-fluid"
                                      src={banner.img}
                                      alt=""
                                  />
                                </div>
                              </div>
                            </div>
                          </SwiperSlide>
                      ))
              )}
            </Swiper>
            <div className="prev">
              <MdOutlineKeyboardArrowLeft />
            </div>
            <div className="next">
              <MdOutlineNavigateNext />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
